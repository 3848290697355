@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body{
  font-family: var(--font-roboto);
  background-color: #323538;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#game-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

#left-container{
  text-align: left;
  margin-top: 3%;
  color: white;
  font-size: 1.25rem;
  width: 20%;
}

#red{
  color: rgb(226, 53, 30);
}

#blue{
  color: #339ecf;
}

#total-moves{
  padding-top: 30%;
}

.turn{
  letter-spacing: 1px;
}

.turn.b{
  color:#339ecf;
}

.turn.r{
  color: rgb(226, 53, 30);
}

#game-state{
  text-align: right;
  margin-top: 3%;
  color: white;
  font-size: 2rem;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#game-state-string{
  font-size: 2vw;
  text-align: center;
  align-items: center;
}

#board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1px;
  border: solid 3px black;
  box-shadow: 5px 5px 7px #131314, -5px -5px 7px #222527;
  width: 100%;
}

.square{
  width: 4vw;
  height: 4vw;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3vw;
  cursor: pointer;
}

.square.black {
  background-color: #282b2e;
  color: white;
}

.square.white {
  background-color: #FFFDD0;
  color: black;
}

.square.b{
  background-image: url("img/blue-pawn.png");
}

.square.bk{
  background-image: url("img/blue-king.png");
}

.square.r{
  background-image: url("img/red-pawn.png");
}

.square.rk{
  background-image: url("img/red-king.png");
}

.square.highlight{
  background-color: yellowgreen;
}

#title{
  font-size: 3rem;
  letter-spacing: 0.9px;
  color: #7095b4;
  font-family: var(--font-raleway);
  padding: 0.75%;
}

@media (max-width: 768px) {
  #main-container {
    flex-direction: column;
    justify-content: center;
  }

  #game-container{
    align-items: center;
  }

  #left-container{
    display: flex;
    flex-direction: row;
    gap:1vw;
    font-size:3vw;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  #total-moves{
    padding-top: 0%;
  }

  #title{
    font-size: 10vw;
    align-items: center;
  }

  #game-state{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 6vw;
    width: 100%;
  }

  #game-state-string{
    font-size: 4vw;
  }

  .square{
    height: 10vw;
    width: 10vw;
    font-size: 3.5vw;
  }
}







